<template>
    <div>
        <ts-page-title
            :title="$t('dailyAttendances.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('dailyAttendances.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper class="row">
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{ $t("dailyAttendances.date") }}</label
                    >
                    <DatePicker
                        class="tw-w-full"
                        :value="date"
                        @on-change="onChange"
                        format="dd-MM-yyyy"
                        type="date"
                        :class="{
                            'ivu-form-item-error': errors.has('date')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('date')"
                    >
                        {{ errors.first("date") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{
                            $t("dailyAttendances.organisationalStructure")
                        }}</label
                    >
                    <Select
                        v-model.number="org_chart_structure_id"
                        clearable
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'org_chart_structure_id'
                            )
                        }"
                    >
                        <Option
                            v-for="(orgChartStructure,
                            index) in orgChartStructures"
                            :key="index"
                            :value="orgChartStructure.org_chart_structure_id"
                            >{{ orgChartStructure.org_chart_structure }}
                        </Option>
                    </Select>
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('org_chart_structure_id')"
                    >
                        {{ errors.first("org_chart_structure_id") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label class="form-col-label control-label tw-mb-2">{{
                        $t("dailyAttendances.status")
                    }}</label>
                    <Select v-model="status">
                        <Option selected>All</Option>
                        <Option value="Present">Present</Option>
                        <Option value="Absent">Absent</Option>
                        <Option value="Take Leave">Take Leave</Option>
                    </Select>
                </div>
                <div class="col-md-2 form-group tw-px-2 tw-pt-7">
                    <Button
                        class="tw-mb-1"
                        type="info"
                        ghost
                        @click="fetchData"
                        >{{ $t("search") }}
                    </Button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                border
                stripe
            >
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "daily-attendanceIndex",
    components: {},
    data() {
        return {
            date: moment(new Date()).format("DD-MM-YYYY"),
            errors: new Errors(),
            loading: false,
            org_chart_structure_id: null,
            status: null,
            data: null
        };
    },
    computed: {
        ...mapState("humanResource/dailyAttendance", [
            "resources",
            "pagination",
            "orgChartStructures"
        ]),
        columns() {
            return [
                {
                    title: this.$t("dailyAttendances.cardNumber"),
                    key: "card_number",
                    width: "120"
                },
                {
                    title: this.$t("dailyAttendances.employeeName"),
                    key: "employee_name",
                    width: "150"
                },
                {
                    title: this.$t("dailyAttendances.shift"),
                    key: "shift",
                    width: "100"
                },
                {
                    title: this.$t("dailyAttendances.position"),
                    key: "position",
                    width: "100"
                },
                {
                    title: this.$t("dailyAttendances.t1"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("dailyAttendances.checkIn"),
                            align: "center",
                            key: "t1"
                        },
                        {
                            title: this.$t("dailyAttendances.checkOut"),
                            align: "center",
                            key: "t2"
                        }
                    ]
                },
                {
                    title: this.$t("dailyAttendances.total1"),
                    key: "total1",
                    align: "center"
                },
                {
                    title: this.$t("dailyAttendances.t2"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("dailyAttendances.checkIn"),
                            align: "center",
                            key: "t3"
                        },
                        {
                            title: this.$t("dailyAttendances.checkOut"),
                            align: "center",
                            key: "t4"
                        }
                    ]
                },
                {
                    title: this.$t("dailyAttendances.total2"),
                    key: "total2",
                    align: "center"
                },
                {
                    title: this.$t("dailyAttendances.t3"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("dailyAttendances.checkIn"),
                            align: "center",
                            key: "t5"
                        },
                        {
                            title: this.$t("dailyAttendances.checkOut"),
                            align: "center",
                            key: "t6"
                        }
                    ]
                },
                {
                    title: this.$t("dailyAttendances.total3"),
                    key: "total3",
                    align: "center"
                },
                {
                    title: this.$t("dailyAttendances.t4"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("dailyAttendances.checkIn"),
                            align: "center",
                            key: "t7"
                        },
                        {
                            title: this.$t("dailyAttendances.checkOut"),
                            align: "center",
                            key: "t8"
                        }
                    ]
                },
                {
                    title: this.$t("dailyAttendances.total4"),
                    key: "total4",
                    align: "center"
                }
            ];
        }
    },
    methods: {
        ...mapActions("humanResource/dailyAttendance", [
            "getOrgChartStructure"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getOrgChartStructure();
            this.loading = false;
        },
        onChange(data) {
            this.date = data;
        },
        async fetchData(attributes) {
            this.loading = true;
            this.errors = new Errors();
            this.$store
                .dispatch("humanResource/dailyAttendance/fetch", {
                    date: this.date,
                    org_chart_structure_id: this.org_chart_structure_id,
                    status: this.status,
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "DAILY ATTENDANCES",
                desc: not.text
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/dailyAttendance/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    }
};
</script>
